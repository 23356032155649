////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import _, { orderBy, isUndefined } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import { apiUpdateOrderPoFac, apiUpdateOrderGeographicalArea, apiUpdateWishedExpeditionDate, apiTakeOwnership,
         apiGetReassignOrder, apiShowModalReassign, apiSetOrderID } from '../../actions/orders'
import DateFormatter from '../../components/Sematable/ColumnHelpers/DateFormatter'
import IdExtFaconnier from '../Forms/IdExtFaconnier'
import RequestDate from '../Forms/Information/RequestDate'
import * as c from '../../constants'
import { checkRoleHasRightsToFunction } from '../misc/allowedRolesPerFunction'
import Menu from 'material-ui/svg-icons/navigation/menu'
import { theme } from '../../components/styledComponents'
import SelectField from 'material-ui/SelectField'
import './OrderDetailInformation.css'
import { hasOwnerShipIfFac, getLocalStorage } from '../../utils/apiUtils'
import BrandSeason from '../Forms/BrandSeason'
/////////ASSETS/////////
import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'

/////////STYLED/////////
const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  margin-bottom: 4rem;
  & > * {
    background-color: ${theme.colors.lightGrey};
    flex: 1 1 25%;
    display: flex;
    align-items: center;
    max-width: calc(33% - 10px);
    min-width: 380px;
    min-height: 12rem;
  }
  @media (max-width: 1460px) {
    flex-direction: column;
    align-items: center;
    & > * {
      flex: 1 1 100 %;
      max-width: 50%;
      border: none;
    }
  }
  @media (max-width: 768px) {
    & > * {
      max-width: 100%;
    }
  }
`
const Column = styled.div`
  width: 100%;
  padding-top: 1em;
  padding-right: 1.5em;
  padding-bottom: 1em;
  padding-left: 1.5em;
  display: flex;
  font-size: 12px !important;
  line-height: 20px !important;
  text-align: left;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  & > * {
    text-transform: uppercase;
  }
  & > *:last-child {
    padding-bottom: 0;
  }
`
const ColorItem = styled.span`
  color: ${(props) => (props.status_Color ? props.status_Color : 'black')};
  font-size: 12px;
  padding: 7px;
  font-weight: bold;
  right: 0px;
  position: absolute;
`
const ColumnLine = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 0px;
  width: 100%;
  max-width: 400px;
  min-height: 2.5rem;
`
const ColumnLineLast = ColumnLine.extend`
`
const ColumnLineMiddle = ColumnLine.extend`
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0px;
`
const ColumnLineLarge = ColumnLine.extend`
`
const Form = styled.form`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
/////////STYLED/////////

const styles = {
  maxWidth: { maxWidth: 10000 },
  noWrap: { whiteSpace: 'nowrap' }
}

class OrderDetailInformation extends Component {

  state = { 
    openMenu: false, 
    isAvailableActions: false 
  }
  
  static defaultProps = {
    deliveryZoneDisabled: true
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    const { order, disabledEdit = false } = this.props
    const ownerShip = hasOwnerShipIfFac(order) && !disabledEdit
    this.setState({ geaographical: this.props.order.iD_Adresse_Livraison })
    if (ownerShip || !order.hasOwnerShip || order.canCancel || order.canReassignGarmentMaker) this.setState({ isAvailableActions: true })
  }

  componentWillReceiveProps(nextProps) {
    const iDAdresseLivraison = _.get(this.props.order, 'iD_Adresse_Livraison')
    const iDAdresseLivraisonNext = _.get(nextProps.order, 'iD_Adresse_Livraison')
    if (iDAdresseLivraison !== iDAdresseLivraisonNext) this.setState({ geaographical: iDAdresseLivraisonNext })
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onChangeGeographical = (event, index, value) => {
    const {
      iD_Commande,
      iD_Printshop,
      allow_Partial_Delivery,
      iD_Mode_Livraison,
      iD_Commande_Externe_Faconnier,
      date_Validated
    } = this.props.order
    const iD_Adresse = value
    this.setState({ geaographical: value })
    const userModel = {
      ID_Delivery_Address: iD_Adresse,
      iD_Commande: parseInt(iD_Commande, 10),
      iD_Commande_Externe_Faconnier,
      iD_Printshop, // TODO : this.props.order.printshop : once we will have the printshop ID.
      Allow_Partial_Delivery: allow_Partial_Delivery ? allow_Partial_Delivery : false,
      ID_Delivery_Mode: iD_Mode_Livraison,
      Order_Delivery_Validated: date_Validated
    }
    if (iD_Adresse) return this.props.apiUpdateOrderGeographicalArea(userModel, iD_Commande)
  }

  handleTakeOwnership() {
    this.props.apiTakeOwnership(this.props.order.iD_Commande)
  }

  handleReassign = () => {
    this.props.apiGetReassignOrder(this.props.order.iD_Commande)
    this.props.apiSetOrderID(this.props.order.iD_Commande)
    this.props.apiShowModalReassign({ openReassign: true })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const {
      trl,
      order,
      myRole,
      apiUpdateOrderPoFac,
      handleCancelOrder,
      showDeliveryZone,
      garmentMakerStatic,
      deliveryZoneDisabled,
      apiUpdateWishedExpeditionDate,
      disabledEdit = false
    } = this.props

    const { isAvailableActions } = this.state
    const ownerShip = hasOwnerShipIfFac(order) && !disabledEdit
    const geoAreas = orderBy(order.possible_Delivery_Addresses, ['geographical_Area'], ['asc']).map((o) => ({
      iD_Adresse: o.iD_Adresse,
      geographical_Area: o.geographical_Area
    }))
    const selectedDeliveryZone = _.find(geoAreas, { iD_Adresse: this.state.geaographical })
    let unicGeoAreas = deliveryZoneDisabled
      ? geoAreas
      : _.uniqBy(geoAreas, (e) => {
        return e.geographical_Area
      })
    const userRole = getLocalStorage('user_role')
    !isUndefined(selectedDeliveryZone) &&
      unicGeoAreas.splice(
        _.findIndex(unicGeoAreas, { geographical_Area: selectedDeliveryZone.geographical_Area }),
        1,
        selectedDeliveryZone
      )
    const selectedGeoArea = _.find(geoAreas, (GA) => GA.iD_Adresse === this.state.geaographical)
    // eslint-disable-next-line
    const disabelGmPO = order.status.idStatus > 8

    return (
      <ColumnsWrapper>
        {/* Left Column */}
        <Column>
          <ColumnLineLarge>
            <Form>
              <span>
                {trl.common__external_id_est}
                :&nbsp;
              </span>
              <span
                style={{ marginRight: !garmentMakerStatic && !checkRoleHasRightsToFunction(myRole, c.funcUpdateId) && 0 }}
                className="righAligned"
              >
                {order.iD_Commande_Externe_Enseigne}
              </span>
            </Form>
          </ColumnLineLarge>
          {
            userRole === 'FAC' || userRole === 'FACA' ?
              <ColumnLineLarge>
                <Form>
                  <span>
                    {trl.common__jpi_order_id}
                    :&nbsp;
                  </span>
                  <span className="righAligned">{order.iD_Commande}</span>
                </Form>
              </ColumnLineLarge>
              : null
          }
          {!garmentMakerStatic && checkRoleHasRightsToFunction(myRole, c.funcUpdateId) && !disabelGmPO && ownerShip ? (
            <ColumnLineLarge>
              <IdExtFaconnier
                trl={trl}
                orderId={order.iD_Commande_Externe_Faconnier}
                order={order}
                apiUpdateOrderPoFac={apiUpdateOrderPoFac}
              />
            </ColumnLineLarge>
          ) : (
            <ColumnLineLarge>
              <Form>
                <span>
                  {trl.common__external_id_fac}
                  :&nbsp;
                </span>
                <span className="righAligned">{order.iD_Commande_Externe_Faconnier}</span>
              </Form>
            </ColumnLineLarge>
          )}
          {
            userRole !== 'FAC' && userRole !== 'FACA' ?
              <ColumnLineLarge>
                <Form>
                  <span>
                    {trl.common__jpi_order_id}
                    :&nbsp;
                  </span>
                  <span className="righAligned">{order.iD_Commande}</span>
                </Form>
              </ColumnLineLarge>
              : null
          }

          {!garmentMakerStatic && checkRoleHasRightsToFunction(myRole, c.funcUpdateId) && !disabelGmPO && ownerShip ? (
            <ColumnLineLarge style={{ margin: '7px 0' }}>
              <BrandSeason
                trl={trl}
                field={'brandSeason'}
                order={order}
                apiUpdateOrderPoFac={apiUpdateOrderPoFac}
                tradKeyField={'orderDetail_OrderHeaders_brand_season'}
              />
            </ColumnLineLarge>
          ) : (
            <ColumnLineLarge>
              <Form>
                <span>
                  {trl.orderDetail_OrderHeaders_brand_season}
                  :&nbsp;
                </span>
                <span className="righAligned">{order.brandSeason}</span>
              </Form>
            </ColumnLineLarge>
          )}
          {!garmentMakerStatic && checkRoleHasRightsToFunction(myRole, c.funcUpdateId) && !disabelGmPO && ownerShip ? (
            <ColumnLineLarge>
              <BrandSeason
                trl={trl}
                field={'brandDrop'}
                order={order}
                apiUpdateOrderPoFac={apiUpdateOrderPoFac}
                tradKeyField={'orderDetail_OrderHeaders_brand_drop'}
              />
            </ColumnLineLarge>
          ) : (
            <ColumnLineLarge>
              <Form>
                <span>
                  {trl.orderDetail_OrderHeaders_brand_drop}
                  :&nbsp;
                </span>
                <span className="righAligned">{order.brandDrop}</span>
              </Form>
            </ColumnLineLarge>
          )}
        </Column>
        {/* Middle Column */}
        <Column>
          <ColumnLineMiddle>
            <Form>
              <span style={styles.noWrap}>
                {trl.common__status}
                :&nbsp;
              </span>
              <ColorItem status_Color={order.status.color}>{trl[order.status.traductionKey]} </ColorItem>
            </Form>
          </ColumnLineMiddle>
          <ColumnLineMiddle>
            <Form>
              <span style={styles.noWrap}>
                {trl.orderDetail_OrderHeaders_DeliveryZone}
                :&nbsp;
              </span>
              {checkRoleHasRightsToFunction(myRole, c.updateDeliveryZone) && !disabledEdit ? (
                <div className="SelectFieldTextRight">
                  <SelectField
                    hintText="DELIVERY ZONE" // TODO: traduction
                    onChange={this.onChangeGeographical}
                    name="iD_Adresse"
                    value={this.state.geaographical}
                    fullWidth={false}
                    disabled={deliveryZoneDisabled || !ownerShip}
                    className="deliveryZoneSelector"
                    inputStyle={{ textAlign: 'center', paddingLeft: 35 }}
                    style={{
                      lineHeight: '61',
                      fontSize: theme.font.fontSize
                    }}
                  >
                    {unicGeoAreas.map((g) => (
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        key={g.iD_Adresse}
                        value={g.iD_Adresse}
                        primaryText={g.geographical_Area}
                      />
                    ))}
                  </SelectField>
                </div>
              ) : (
                <span>{selectedGeoArea.geographical_Area}</span>
              )}
            </Form>
          </ColumnLineMiddle>
          {order.canCancel || order.canReassignGarmentMaker || (!order.hasOwnerShip && checkRoleHasRightsToFunction(myRole, c.funcUpdateId)) ? (
            <ColumnLineMiddle>
              <Form>
                {isAvailableActions ? (
                  <div>
                    <span>{trl.orderDetail_OrderHeaders_Action}:</span>
                    <IconMenu
                      iconButtonElement={
                        <Menu style={{ display: 'block', color: !isAvailableActions && 'rgba(0, 0, 0, 0.3)' }} />
                      }
                      style={{
                        cursor: !isAvailableActions ? 'default' : 'pointer',
                        right: '0px',
                        position: 'absolute'
                      }}
                    >
                      {order.canCancel && (
                        <MenuItem
                          style={{ fontSize: theme.font.fontSize }}
                          primaryText={trl.common__order_cancel}
                          onClick={handleCancelOrder}
                        />
                      )}
                      {!order.hasOwnerShip && checkRoleHasRightsToFunction(myRole, c.funcUpdateId) && (
                        <MenuItem
                          style={{ fontSize: theme.font.fontSize }}
                          primaryText={trl.ofu_lvlOrder_Action_TakeOrderCreation}
                          onClick={() => this.handleTakeOwnership()}
                        />
                      )}
                      {order.canReassignGarmentMaker && checkRoleHasRightsToFunction(myRole, c.reassignOrder) &&
                        <MenuItem
                          style={{ fontSize: theme.font.fontSize }}
                          primaryText={trl.order_action_reassignOrder}
                          onClick={() => this.handleReassign()}
                        />
                      }
                    </IconMenu>
                  </div>
                ) : null}
              </Form>
            </ColumnLineMiddle>
          ) : null}
          {showDeliveryZone && (
            <ColumnLineMiddle>
              <Form>
                <span>
                  <ColorItem status_Color={order.status.color}>{trl[order.status.traductionKey]}</ColorItem>
                </span>
              </Form>
            </ColumnLineMiddle>
          )}
        </Column>
        {/* Right Column */}
        <Column>
          <ColumnLineLast>
            <Form>
              <span>
                {trl.orderDetail_OrderHeaders_ValidationDate}
                :&nbsp;
              </span>
              {order.date_Validated ? (
                <div className="orderFormatedDate">
                  <DateFormatter>{order.date_Validated}</DateFormatter>
                </div>
              ) : null}
            </Form>
          </ColumnLineLast>

          {!disabelGmPO && checkRoleHasRightsToFunction(myRole, c.funcUpdateId) && ownerShip ? (
            <ColumnLineLast>
              <RequestDate
                trl={trl}
                orderId={order.iD_Commande_Externe_Faconnier}
                order={order}
                apiUpdateOrderPoFac={apiUpdateWishedExpeditionDate}
              />
            </ColumnLineLast>
          ) : (
            <ColumnLineLast>
              <Form>
                <span>
                  {trl.orderDetail_OrderHeaders_RequestedDate}
                  &nbsp;
                </span>
                <div className="orderFormatedDate">
                  <DateFormatter>{order.requestedShipping_Date}</DateFormatter>
                </div>
              </Form>
            </ColumnLineLast>
          )}
          <ColumnLineLast>
            <Form>
              <span>
                {trl.orderDetail_OrderHeaders_BrandDeadline}
                :&nbsp;
              </span>
              {order.date_Warehouse_Enseigne ? (
                <div className="orderFormatedDate">
                  <DateFormatter>{order.date_Warehouse_Enseigne}</DateFormatter>
                </div>
              ) : null}
            </Form>
          </ColumnLineLast>
        </Column>
      </ColumnsWrapper>
    )
  }
}

OrderDetailInformation.propTypes = {
  trl: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  myRole: PropTypes.string.isRequired,
  apiUpdateOrderPoFac: PropTypes.func.isRequired,
  deliveryZoneDisabled: PropTypes.bool
}

OrderDetailInformation.contextTypes = {
  router: PropTypes.object.isRequired
}

OrderDetailInformation = reduxForm({
  form: 'orderDetailInformation',
  touchOnBlur: false,
  errors: {}
})(OrderDetailInformation)

export default connect(null, {
  apiUpdateOrderPoFac,
  apiUpdateOrderGeographicalArea,
  apiUpdateWishedExpeditionDate,
  apiTakeOwnership,
  apiGetReassignOrder,
  apiShowModalReassign,
  apiSetOrderID
})(OrderDetailInformation)
