////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _, { get, map, includes, find, isNull, isUndefined, filter } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import OrderSkuTableBat from '../Sematable/Tables/OrderSkuTableBat'
import * as SC from '../styledComponents'
import { theme } from '../styledComponents'

/////////STYLED/////////
const styles = { h5: { fontWeight: 'bold', paddingTop: 4, textTransform: 'uppercase', marginBottom: 1 } }
const DuplicateOrderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
`
const DuplicateOrderText = styled.div`
  border: 7px solid ${theme.colors.red};
  font-size: 3rem;
  font-weight: bold;
  padding: 5px 10px;
`
/////////STYLED/////////

class Bat extends Component {
  constructor(props) {
    super(props)
    this.state = { isBatView: true, isDuplicatedOrder: false }
    this.renderComposition = this.renderComposition.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    const { type } = this.props
    this.setState({ isBatView: type === 'viewBat' })
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  getOptionNameFromValue(item) {
    let values = get(item, 'props.selectedValues[0].listValue', [])
    if (isNull(values) || values.length < 1) {
      map(item, (e) => {
        values.push(get(e, 'props.selectedValues[0].unicValue', []))
      })
      if (isNull(values) || values.length < 1) return undefined
    }

    if (typeof values[0] !== 'string') {
      const selectedOptions = values
        .map((value) => {
          const optionValue = find(item.option, (option) => option.value === value)
          if (optionValue) return optionValue.primaryText
        })
        .filter((n) => n !== undefined)
      return selectedOptions.length > 1 ? selectedOptions : selectedOptions[0]
    } else return values
    //}
  }

  ///////////////////////////////// HANDLE RENDERS /////////////////////////////////

  renderTableSku() {
    const { data, trl, dontShowCompo, dontShowCommon } = this.props
    const { isBatView } = this.state
    const articleChampListSKU = get(data, 'listSKU', [])
    let numTitle = 1
    !dontShowCompo && numTitle++
    !dontShowCommon && numTitle++
    return (
      <div style={!isBatView ? {} : { marginTop: 30, marginBottom: 10 }}>
        <h3 style={{ fontWeight: 'bold' }}>{`${numTitle}/ ${trl.batViewAndDecide_textTitle_variableData}`}</h3>
        <OrderSkuTableBat data={articleChampListSKU} articleVariable={data} trl={trl} />
      </div>
    )
  }

  renderCommonData() {
    const { data, trl, dontShowCompo } = this.props
    const commonData = get(data, 'article_Champ_CommonData', [])
    const compoData = get(data, 'article_Champ_Compo', [])
    const brandPo = []
    if (_.isEmpty(commonData)) return
    const commonsDataObj = commonData
      .map((field) => {
        const typeValue = get(field, 'props.typeValue.name')
        if (
          !includes(['careinstruction', 'madein', 'price_int', 'price_currency', 'price_decim'], typeValue) &&
          !includes(typeValue, 'caresymbol')
        ) {
          if (field.type === 'textfield' && field.props.selectedValues[0]) {
            const obj = {
              name: field.props.label,
              value: field.props.selectedValues[0].unicValue,
              type: field.type
            }
            if (field.referenceCode !== 'brandPo') {
              return obj
            } else {
              brandPo.push(obj)
            }
          } else if (field.type === 'radio' && this.getOptionNameFromValue(field)) {
            const val = field.props.displayFormat !== 'type_9' ? this.getOptionNameFromValue(field) : null
            return {
              name: field.props.label,
              value: val,
              imageUrl: val,
              type: field.type
            }
          } else if (field.type === 'checkbox' && this.getOptionNameFromValue(field)) {
            const val = field.props.displayFormat !== 'type_7' ? this.getOptionNameFromValue(field) : null
            return {
              name: field.props.label,
              value: val,
              imageUrl: val,
              type: field.type
            }
          }
        }
      })
      .filter((n) => n !== undefined)
    return (
      <div style={!dontShowCompo ? { marginTop: 30 } : {}}>
        <h3 style={{ fontWeight: 'bold', marginTop: '0px', marginBottom: '0px' }}>
          {`${compoData.length > 0 ? '2/' : '1/'} ${trl.batViewAndDecide_textTitle_comonData}`}
        </h3>
        {this.renderCareSymbols()}
        {this.renderMadeIn()}
        {brandPo.map((field, k) => (
          <div key={`${field.type}-${k}`}>
            <h5 style={styles.h5}>{field.name}</h5>
            <div>{field.value && !Array.isArray(field.value) && <span>{field.value}</span>}</div>
          </div>
        ))}
        {this.renderPrice()}
        {this.renderCareInstructions()}
        {commonsDataObj.map((field) => (
          <div key={field.name}>
            <h5 style={styles.h5}>{field.name}</h5>
            <div>{field.value && !Array.isArray(field.value) && <span>{field.value}</span>}</div>
            <div>{field.imageUrl && !Array.isArray(field.imageUrl) && <img src={field.imageUrl} alt="img" />}</div>
            <div>
              {field.value && Array.isArray(field.value) && (
                <span style={{ marginLeft: -10 }}>
                  {field.value.map((value, index) => (
                    <span key={field.value + '' + index}>
                      <span style={{ padding: 10 }}>{value}</span>
                      {index + 1 < field.value.length && <span>-</span>}
                    </span>
                  ))}
                </span>
              )}
              {field.imageUrl && Array.isArray(field.imageUrl) && (
                <span style={{ marginLeft: -10 }}>
                  {field.imageUrl.map((imageUrl) => (
                    <span key={imageUrl}>
                      <img style={{ padding: 10 }} src={imageUrl} alt="img" />
                    </span>
                  ))}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    )
  }

  renderCareSymbols() {
    const { data } = this.props
    const CommonData = get(data, 'article_Champ_CommonData')
    const colors = ['#50aa36', '#f7b133', '#e84e2e', '#a3335b', '#1e70b7']
    const careSymbols = CommonData.map((field) => {
      const typeValue = get(field, 'props.typeValue.name')
      if (includes(typeValue, 'caresymbol')) {
        return this.getOptionNameFromValue(field)
      }
    }).filter((n) => n !== undefined)
    if (careSymbols.length < 1) return null
    return (
      <div>
        <h5 style={styles.h5}>CARE SYMBOLS</h5>

        <div
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Ginetex-Symbols',
            fontSize: 30,
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          {careSymbols.map((symbole, index) => (
            <div
              style={{
                width: 66,
                height: 42,
                textAlign: 'center',
                backgroundColor: colors[index],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 10,
                border: '1px solid rgba(0, 0, 0, 0.87)'
              }}
              key={symbole}
            >
              {symbole}
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderCareInstructions() {
    const { data } = this.props
    const CommonData = get(data, 'article_Champ_CommonData')
    const careInstructions = CommonData.map((field) => {
      const typeValue = get(field, 'props.typeValue.name')
      if (typeValue === 'careinstruction') {
        return this.getOptionNameFromValue(field)
      }
    }).filter((n) => n !== undefined)
    if (careInstructions.length < 1) return null
    return (
      <div>
        <h5 style={styles.h5}>CARE INSTRUCTIONS</h5>
        <div style={{ marginLeft: -10 }}>
          {careInstructions.map((instruction, index) => (
            <span key={instruction}>
              <span style={{ padding: 10 }}>{instruction}</span>
              {index + 1 < careInstructions.length && <span>-</span>}
            </span>
          ))}
        </div>
      </div>
    )
  }

  renderMadeIn() {
    const { data } = this.props
    const CommonData = get(data, 'article_Champ_CommonData')
    const madein = find(CommonData, (itemTissu) => {
      const typeValue = get(itemTissu, 'props.typeValue.name')
      return typeValue === 'madein'
    })
    if (!madein || !this.getOptionNameFromValue(madein)) return null
    return (
      <div>
        <h5 style={styles.h5}>{madein.props.label}</h5>
        {this.getOptionNameFromValue(madein)}
      </div>
    )
  }

  renderPrice() {
    const { data } = this.props
    const CommonData = get(data, 'article_Champ_CommonData')
    const priceInt = filter(CommonData, (itemTissu) => {
      const typeValue = get(itemTissu, 'props.typeValue.name')
      return typeValue === 'price_int'
    })
    // If no price is found, no render
    if (!priceInt || !this.getOptionNameFromValue(priceInt)) return null
    // Optional currency and decimal values
    const priceDevise = filter(CommonData, (itemTissu) => {
      const typeValue = get(itemTissu, 'props.typeValue.name')
      return typeValue === 'price_currency'
    })
    const priceDecimal = filter(CommonData, (itemTissu) => {
      const typeValue = get(itemTissu, 'props.typeValue.name')
      return typeValue === 'price_decim'
    })
    const output = map(priceInt, (elem) => {
      return (
        <div>
          <h5 style={styles.h5}>{!isUndefined(elem.props.label) && elem.props.label}</h5>
          {elem.props.selectedValues[0].unicValue}{' '}
          {!isUndefined(priceDevise) && this.getOptionNameFromValue(priceDevise[0])}{' '}
          {!isUndefined(priceDecimal) && this.getOptionNameFromValue(priceDecimal)}
        </div>
      )
    })
    return output
  }

  renderComposition() {
    const { data, dontShowCompo } = this.props
    if (dontShowCompo) return null
    const compositionField = get(data, 'article_Champ_Compo')
    if (_.isEmpty(compositionField)) return
    const compositionObj = map(compositionField, (itemTissu, index) => {
      const typeValue = get(itemTissu, 'props.typeValue.name')
      if (typeValue === 'tissu') {
        const tissu = compositionField[index]
        if (
          !isUndefined(tissu.props.selectedValues[0]) &&
          tissu.props.selectedValues[0] &&
          tissu.props.selectedValues[0].listValue.length
        ) {
          const matiers = map(compositionField, (matier) => {
            if (
              !isUndefined(matier.props.selectedValues[0]) &&
              matier.props.parentField === tissu.id_Article_Champ &&
              matier.props.selectedValues[0].listValue.length
            ) {
              const percentMater = find(
                compositionField,
                (field) => field.props.parentField === matier.id_Article_Champ
              )
              if (percentMater.props.selectedValues[0] && percentMater.props.selectedValues[0].unicValue !== '')
                return {
                  name: this.getOptionNameFromValue(matier),
                  value: percentMater.props.selectedValues[0].unicValue
                }
            }
          }).filter((n) => n !== undefined)
          const compoTissu = {
            name: this.getOptionNameFromValue(tissu),
            matiers
          }
          return compoTissu
        }
      }
    }).filter((n) => n !== undefined)

    return (
      <div>
        <h3 style={{ fontWeight: 'bold' }}>{`1/ ${'COMPOSITION'}`}</h3>
        {compositionObj.map((item) => (
          <div key={item.name}>
            - <span style={{ textTransform: 'uppercase', paddingRight: 5 }}>{item.name} :</span>
            {item.matiers.map((matier, index) => (
              <span key={matier.name}>
                <span style={{ padding: 10 }}>
                  {matier.value}% {matier.name}
                </span>
                {index + 1 < item.matiers.length && <span>-</span>}
              </span>
            ))}
          </div>
        ))}
      </div>
    )
  }

  renderPdf() {
    const { batsList } = this.props
    if (Object.hasOwn(batsList[0], 'batPdfFilePath') && batsList[0].batPdfFilePath.length > 0) {
      return (
        <div>
          <h5 style={styles.h5}>4/ VALIDATION FILE</h5>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '15px'
            }}
          >
            <iframe
              style={{ width: '80%', height: '666px' }}
              src={`/pdf_from_sap/bat/${batsList[0].batPdfFilePath}.pdf`}
              type="application/pdf"
              title="title"
            />
          </div>
        </div>
      )
    } else {
      return null
    }
  }
  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { articleVariableChangeStep, isDisabled, dontShowCompo = false, dontShowCommon = false, trl } = this.props
    const { isBatView } = this.state
    return (
      <div style={!isBatView ? {} : { marginBottom: 80 }}>
        {this.props.data.isDuplicatedOrder && (
          <DuplicateOrderWrapper>
            <DuplicateOrderText>{trl.batViewAndDecide_textSubtitle_duplicatedOrder}</DuplicateOrderText>
          </DuplicateOrderWrapper>
        )}
        {!dontShowCompo && this.renderComposition()}
        {!isBatView && !dontShowCompo && (
          <div style={{ padding: 80, textAlign: 'center' }}>
            <SC.ButtonS disabled={isDisabled} onClick={() => articleVariableChangeStep(0)}>
              {trl.batView_btn_changeData}
            </SC.ButtonS>
          </div>
        )}
        {!dontShowCommon && this.renderCommonData()}
        {!isBatView && !dontShowCommon && (
          <div style={{ padding: 80, textAlign: 'center' }}>
            <SC.ButtonS disabled={isDisabled} onClick={() => articleVariableChangeStep(1)}>
              {trl.batView_btn_changeData}
            </SC.ButtonS>
          </div>
        )}
        {this.renderTableSku()}
        {Object.hasOwn(this.props, 'batsList') && this.renderPdf()}
        {!isBatView && (
          <div style={{ padding: 80, textAlign: 'center' }}>
            <SC.ButtonS disabled={isDisabled} onClick={() => articleVariableChangeStep(2)}>
              {trl.batView_btn_changeData}
            </SC.ButtonS>
          </div>
        )}
      </div>
    )
  }
}

export default Bat

Bat.propTypes = {
  trl: PropTypes.object.isRequired,
  data: PropTypes.data,
  articleVariableChangeStep: PropTypes.func,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  dontShowCompo: PropTypes.bool,
  dontShowCommon: PropTypes.bool,
  batsList: PropTypes.object
}
