////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as MUI from 'material-ui'
import styled from 'styled-components'
///////COMPONENTS///////
import { Aztec } from '../react-aztec'
import validation from '../react-aztec/dist-modules/helpers/validation'
/////////ASSETS/////////
import BleachIcon from '../../assets/care-symbols/bleach.png'
import DryCleanIcon from '../../assets/care-symbols/dry-clean.png'
import IroningIcon from '../../assets/care-symbols/ironing.png'
import WashIcon from '../../assets/care-symbols/wash.png'
import TumbleDryIcon from '../../assets/care-symbols/tumble-dry.png'
//

/////////STYLED/////////
const AztecWrapper = styled.div`
  & div[name='CS1'] > div > div > div:nth-child(2) {
    left: 14px !important;
    top: -5px !important;
  }
  & div[name='CS2'] > div > div > div:nth-child(2) {
    left: 20px !important;
  }
  & div[name='CS3'] > div > div > div:nth-child(2) {
    left: 19px !important;
  }
  & div[name='CS4'] > div > div > div:nth-child(2) {
    left: 20px !important;
    top: -3px !important;
  }
  & div[name='CS5'] > div > div > div:nth-child(2) {
    left: 19px !important;
  }
`
/////////STYLED/////////

const imgArray = [WashIcon, BleachIcon, TumbleDryIcon, IroningIcon, DryCleanIcon]
class AztecComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: [],
      formDataOrigin: [],
      displayFormErrors: false,
      formOptions: {
        careinstruction: [],
        tissu: [],
        matiere: [],
        isInline: false
      },
      oneAsteriskFlag: false, // false or id of field
      twoAsterisksFlag: false // false or id of field
    }
    this.triggerSubmit = this.triggerSubmit.bind(this)
    this.handelAztecChange = this.handelAztecChange.bind(this)
    this.handelAztecOnBlur = this.handelAztecOnBlur.bind(this)
    this.handelAztecOnCheck = this.handelAztecOnCheck.bind(this)
    this.careSymbole = 0
    this.isSubmited = false
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    if (this.props.data !== undefined && !_.isNull(this.props.data)) this.onPropsChange(this.props.data)
  }

  componentWillReceiveProps(nextProps) {
    this.onPropsChange(nextProps.data)
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onPropsChange(propsData) {
    let data = _.cloneDeep(propsData)
    data = this.fieldSort(data)
    this.dataPriceSort(data)
    let updatatedLayoutPrice = data
    this.dataCaresymbolSort(updatatedLayoutPrice)
    let updatatedLayoutDataParent = this.dataParentSort(updatatedLayoutPrice)
    let updatatedLayoutData = this.preFormat(updatatedLayoutDataParent)
    this.setState({ formData: updatatedLayoutData, formDataOrigin: updatatedLayoutData }, () => {
      const findIndexCareInstruction = {
        index: _.findIndex(updatatedLayoutData, (item) => {
          const typeValue = _.get(item, 'props.typeValue.name')
          if (typeValue == 'careinstruction') return true
        }),
        type: 'careinstruction'
      }
      const findIndexTissu = {
        index: _.findIndex(updatatedLayoutData, (item) => {
          const typeValue = _.get(item, 'props.typeValue.name')
          if (typeValue == 'tissu') return true
        }),
        type: 'tissu'
      }
      const itemsTissuAllIndex = _.map(updatatedLayoutData, (itemTissu, index) => {
        const typeValue = _.get(itemTissu, 'props.typeValue.name')
        if (typeValue == 'tissu')
          return {
            index: index,
            type: 'matiere'
          }
      }).filter((n) => n !== undefined)
      const formDataOrigin = _.cloneDeep(updatatedLayoutData)
      this.initAztecForm(
        updatatedLayoutData,
        formDataOrigin,
        [findIndexTissu, ...itemsTissuAllIndex, findIndexCareInstruction],
        1
      )
    })
  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve()
      })
    })
  }

  initAztecForm(updatatedLayoutData, formDataOrigin, itemsIndex = []) {
    const { formOptions } = this.state
    const itemOpptionsMap = {}
    itemsIndex.forEach((ii) => {
      if (ii == -1 || ii.index == -1) return
      const itemIndex = ii.type == 'matiere' ? ii.index + 2 : ii.index
      const item = updatatedLayoutData[itemIndex]
      const itemOpptions = _.cloneDeep(item.option)
      itemOpptionsMap[ii.type] = itemOpptions
    })
    this.setState({ formOptions: { ...formOptions, ...itemOpptionsMap } }, () => {
      itemsIndex.forEach((ii, index) => {
        if (ii == -1 || ii.index == -1) return
        const itemIndex = ii.type == 'matiere' ? ii.index + 2 : ii.index
        const item = updatatedLayoutData[itemIndex]
        setTimeout(() => {
          this.handelSelectOption({
            optionName: ii.type,
            newFormData: updatatedLayoutData,
            formDataOrigin,
            item: item,
            value: item.props.value,
            firstCall: true
          })
        }, 100 * index)
      })
    })
  }

  getValueId(fakeId) {
    const arrayIds = fakeId.split('__')
    return arrayIds[1]
  }

  manageCustomValidation(formData, formOutput) {
    const { isValidQuantity, isDuplicate } = this.props
    const formEtat = {
      isValid: true,
      errors: []
    }
    formData.map((tissuFiled) => {
      if (_.isFunction(isDuplicate)) {
        const rst = isDuplicate(formData)
        if (rst.isDuplicate) {
          formEtat.isValid = false
          tissuFiled.duplicateError = rst.msg
          formEtat.errors.push(tissuFiled.duplicateError)
        }
      }
      if (_.get(tissuFiled, 'referenceCode') == 'Quantity' && _.isFunction(isValidQuantity)) {
        if (!isValidQuantity(tissuFiled.props.value)) {
          formEtat.isValid = false
          tissuFiled.customRules = {
            rule: 'invalid',
            message: `The quantity is invalid`
          }
          formEtat.errors.push(tissuFiled.customRules)
        }
      }
      if (tissuFiled.type == 'checkbox' && tissuFiled.isLastOption) {
        tissuFiled.customRules = {}
        const checkBoxForm = _.find(formOutput, (res) => res.iD_ItemField == tissuFiled.id_Article_Champ) || {}
        const checkBoxValues = _.get(checkBoxForm, 'values', [])
        if (_.isEmpty(checkBoxValues) && tissuFiled.isMandatory) {
          formEtat.isValid = false

          tissuFiled.customRules = {
            rule: 'required',
            message: `This field could not be empty`
          }
          formEtat.errors.push(tissuFiled.customRules)
        }
      } else if (tissuFiled.props.childFields && tissuFiled.props.parentField == null) {
        const isTissuFiledHasValue =
          tissuFiled.props.selected !== null &&
          tissuFiled.props.selected !== '' &&
          typeof tissuFiled.props.selected === 'number'
        tissuFiled.customRules = {}
        let matierePercentSomme = 0
        tissuFiled.childFields = []
        tissuFiled.props.childFields.forEach((childField, index) => {
          const childIndex = _.findIndex(formData, (field) => field.id == childField)
          const child = formData[childIndex]
          const isSelectHasAValue =
            child.props.selected !== null && child.props.selected !== '' && typeof child.props.selected === 'number'

          if (child !== undefined) {
            child.customRules = {}
            if (isTissuFiledHasValue && !isSelectHasAValue && index == 0) {
              formEtat.isValid = false

              child.customRules = {
                rule: 'required',
                message: `This field could not be empty`
              }
              formEtat.errors.push(child.customRules)
            } else if (index == 0 && isSelectHasAValue && !isTissuFiledHasValue) {
              formEtat.isValid = false

              tissuFiled.customRules = {
                rule: 'required',
                message: `This field could not be empty`
              }
              formEtat.errors.push(tissuFiled.customRules)
            }
            child.childFields = []
            child.props.childFields.forEach((childFieldLevel2) => {
              const childLevel2Index = _.findIndex(formData, (field) => field.id == childFieldLevel2)
              const childLevel2 = formData[childLevel2Index]
              if (childLevel2 !== undefined) {
                childLevel2.customRules = {}
                if (parseFloat(childLevel2.props.value) > 0) {
                  matierePercentSomme += parseFloat(childLevel2.props.value)
                  if (!isSelectHasAValue) {
                    formEtat.isValid = false

                    child.customRules = {
                      rule: 'required',
                      message: `This field could not be empty`
                    }
                    formEtat.errors.push(child.customRules)
                  }
                } else if (isSelectHasAValue || (index == 0 && isTissuFiledHasValue)) {
                  formEtat.isValid = false

                  childLevel2.customRules = {
                    rule: 'required',
                    message: `This field could not be empty`
                  }
                  formEtat.errors.push(childLevel2.customRules)
                }
              }
            })
          }
        })
        if (
          matierePercentSomme !== 100 &&
          (tissuFiled.props.selected !== null || tissuFiled.props.selected !== '') &&
          matierePercentSomme > 0
        ) {
          formEtat.isValid = false
          tissuFiled.customRules = {
            rule: 'percent',
            message: `Total materials in a part should be 100%`
          }
          formEtat.errors.push(tissuFiled.customRules)
        }
      }
    })
    return formEtat
  }

  onSubmit(formValues, errors, formData) {
    const { hasRestrictedCompos } = this.props
    // Those vars are only usefull when we are using restricted compos item
    let valueOneAsteriskSelected = false
    let valueTwoAsterisksSelected = false
    let oneAsteriskPercentage = false

    const formValuesObject = formValues[this.props.guid]
    const formOutput = []
    formData.forEach((field) => {
      const idSaleOrderItemField = _.get(field, 'props.selectedValues.iD_Commande_Article_Champ', 0)
      const primaryText = field.type == 'textfield' ? formValuesObject[field.id_Article_Champ] : null
      let values = []
      const checkBoxItem = _.find(formOutput, (elm) => elm.iD_ItemField == field.id_Article_Champ)

      const filedValue = formValuesObject[field.id]
      if (field.type == 'checkbox') {
        const valueId = parseInt(this.getValueId(field.id), 10)
        if (filedValue) {
          values = checkBoxItem ? checkBoxItem.values.push(valueId) : [valueId]
        }
      } else if (field.type !== 'textfield') {
        if (filedValue && filedValue !== '') {
          values = [filedValue]
        }
        if (field.type == 'radio') {
          field.isSubmited = true
        }
      }
      if (checkBoxItem) {
        //
      } else {
        formOutput.push({
          iD_ItemField: field.id_Article_Champ,
          iD_SaleOrderItemField: idSaleOrderItemField,
          primaryText,
          values
        })
      }
      if (hasRestrictedCompos && !_.isEmpty(field.option)) {
        const selectedLabelValue = _.find(field.option, { value: field.props.value })
        if (!_.isUndefined(selectedLabelValue)) {
          const hasOneAsteriskSelected =
            selectedLabelValue.primaryText.substring(
              selectedLabelValue.primaryText.length,
              selectedLabelValue.primaryText.length - 2
            ) == ' *'
          const hasTwoAsterisksSelected =
            selectedLabelValue.primaryText.substring(
              selectedLabelValue.primaryText.length,
              selectedLabelValue.primaryText.length - 3
            ) == ' **'

          // Assigning value of field if asterisk(s) found
          if (hasOneAsteriskSelected) {
            valueOneAsteriskSelected = field.props.value
            const percentageField = _.find(formData, { referenceCode: `${field.referenceCode}_P` })
            if (!_.isUndefined(percentageField)) {
              oneAsteriskPercentage = percentageField.props.value
            }
          }
          if (hasTwoAsterisksSelected) {
            valueTwoAsterisksSelected = field.props.value
          }
        }
      }
    })
    const model = { Aztec_List: formOutput }
    if (this.props.aditionnelData && !_.isEmpty(this.props.aditionnelData)) {
      model.aditionnelData = this.props.aditionnelData
    }
    // In case of restricted compo item, additional data must be sent
    if (hasRestrictedCompos) {
      if (valueOneAsteriskSelected !== false) {
        model.MatCertif1Id = valueOneAsteriskSelected
        model.hasRestrictedCompos = true
      }
      if (oneAsteriskPercentage !== false) {
        model.MatCertif1Percent = parseInt(oneAsteriskPercentage)
        model.hasRestrictedCompos = true
      }
      if (valueTwoAsterisksSelected !== false) {
        model.MatCertif2Id = valueTwoAsterisksSelected
        model.hasRestrictedCompos = true
      }
    }
    const formEtat = this.manageCustomValidation(formData, formOutput)
    this.setState({ displayFormErrors: true, formData, isSubmited: true })
    if (_.isEmpty(errors) && formEtat.isValid) this.props.submitAction(model)
  }

  triggerSubmit(data) {
    this.formRef.click()
  }

  formatLayout(e, index, data) {
    if (index == 0) this.careSymbole = 0
    let { typeValue, displayFormat, selectedValues } = e.props
    e.id = e.id_Article_Champ
    e.props.name = e.referenceCode
    if (e.type == 'selectfield&translation') e.type = 'selectfield'
    if (Array.isArray(selectedValues)) e.props.selectedValues = selectedValues[0]
    // Check if we have 3 kind of price
    let needles = []
    let haystack = ['Prix_entier', 'Prix_devise', 'Prix_decimal']
    let res = []
    if (this.props.guid.includes('commonData')) {
      _.map(this.props.data, (elem) => {
        needles.push(elem.referenceCode)
      })
    }
    if (!_.isEmpty(needles)) {
      for (const element of haystack) {
        res.push(needles.indexOf(element))
      }
    }
    let addiClass = _.get(typeValue, 'name', '')
    _.forEach(res, (e) => {
      if (e !== -1 && (addiClass == 'price_int' || addiClass == 'price_decim' || addiClass == 'price_currency')) {
        addiClass += ' price'
      } else if (addiClass == 'price_int' && e == -1) {
        addiClass = ''
      }
    })
    if (_.includes(addiClass, 'caresymbol')) {
      e.careSymboleImg = imgArray[this.careSymbole]
      this.careSymbole++
      addiClass = 'caresymbol caresymbol_' + this.careSymbole
    }
    e.className = `${addiClass} ${e.type} `
    if (
      displayFormat == 'type_5' ||
      displayFormat == 'type_12' ||
      displayFormat == 'type_13' ||
      e.type == 'radio' ||
      e.type == 'selectfield'
    ) {
      if (selectedValues.listValue?.length) {
        e.props.value = selectedValues.listValue[0]
        if (e.type == 'radio') e.props.valueSelected = selectedValues.listValue[0]
      }
    } else if (e.type == 'textfield' && selectedValues.unicValue) {
      e.props.value = selectedValues.unicValue
    }
    return e
  }

  formatOptionValues(e) {
    if (e.option && e.option.length > 0) {
      e.option.map((option) => {
        if (e.type == 'radio') {
          option.label = option.primaryText
        }
        return option
      })
    }
    return e
  }

  // find single child element for dataCompo
  findChildElem(data, child) {
    return _.find(data, (childElem) => childElem.id_Article_Champ == child)
  }

  multiSplice(array, ...indexes) {
    indexes.sort((a, b) => a - b)
    indexes.forEach((item, index) => array.splice(item - index, 1))
    return array
  }

  dataCaresymbolSort(data) {
    let caresymbolIndex = -1
    const dataCaresymbolItrem = _.remove(data, (item, index) => {
      const isCaresymbol = item.props.typeValue && item.props.typeValue.name == 'caresymbol'
      if (isCaresymbol && caresymbolIndex < 0) caresymbolIndex = index
      return isCaresymbol
    })

    if (caresymbolIndex >= 0) {
      data.splice(caresymbolIndex, 0, ...dataCaresymbolItrem)
    }
  }
  fieldSort(data) {
    const { isDisabled } = this.props
    if (isDisabled) {
      data.map((d) => {
        d.props.disabled = true
      })
    }
    if (data[0]?.fieldSort !== null && data[0]?.fieldSort !== undefined)
      return _.sortBy(data, (field) => field.fieldSort)
    return data
  }
  dataPriceSort(data) {
    let newData = data
    const firtPriceIndex = _.findIndex(data, (field) => {
      return (
        field.props.typeValue &&
        (field.props.typeValue.name == 'price_int' ||
          field.props.typeValue.name == 'price_decim' ||
          field.props.typeValue.name == 'price_currency')
      )
    })
    if (firtPriceIndex !== -1) {
      let priceInt = _.find(data, (field) => field.props.typeValue && field.props.typeValue.name == 'price_int')
      let priceDecim = _.find(data, (field) => field.props.typeValue && field.props.typeValue.name == 'price_decim')
      let priceCurrency = _.find(
        data,
        (field) => field.props.typeValue && field.props.typeValue.name == 'price_currency'
      )

      priceInt = _.cloneDeep(priceInt)
      priceDecim = _.cloneDeep(priceDecim)
      priceCurrency = _.cloneDeep(priceCurrency)

      const priceIntIndex = _.findIndex(
        data,
        (field) => field.props.typeValue && field.props.typeValue.name == 'price_int'
      )
      const priceDecimIndex = _.findIndex(
        data,
        (field) => field.props.typeValue && field.props.typeValue.name == 'price_decim'
      )
      const priceCurrencyIndex = _.findIndex(
        data,
        (field) => field.props.typeValue && field.props.typeValue.name == 'price_currency'
      )
      if (priceIntIndex > -1) {
        if (priceDecimIndex > -1) {
          if (priceCurrencyIndex > -1) {
            this.multiSplice(data, priceIntIndex, priceDecimIndex, priceCurrencyIndex)
            data.splice(firtPriceIndex, 0, priceInt, priceCurrency, priceDecim)
          } else {
            this.multiSplice(data, priceIntIndex, priceDecimIndex)
            data.splice(firtPriceIndex, 0, priceInt, priceDecim)
          }
        } else {
          this.multiSplice(data, priceIntIndex)
          data.splice(firtPriceIndex, 0, priceInt)
        }
      }
    }
    return newData
  }
  // sort elements for dataCompo
  dataParentSort(data) {
    const newData = []
    const level1 = _.filter(
      data,
      (elem) => elem.props && elem.props.parentField == null && elem.props.childFields?.length
    )
    if (!level1.length) return data
    level1.forEach((parent) => {
      newData.push(parent)
      parent.props.childFields.forEach((child) => {
        const childObj = this.findChildElem(data, child)
        newData.push(childObj)
        childObj.props.childFields.forEach((child2) => {
          const childObj2 = this.findChildElem(data, child2)
          newData.splice(newData.length - 1, 0, childObj2)
        })
      })
    })
    return newData
  }

  preFormat(data) {
    const { isDisabled, guid } = this.props
    this.dataParentSort(data)
    const keyElemes = []
    data.map((e, index) => {
      if (Array.isArray(e.props.selectedValues)) {
        e.props.selectedValues = e.props.selectedValues[0] || {}
        if (guid.includes('commonData')) {
          e.formType = 'commonData'
        }
      }
      this.formatOptionValues(e)
      this.formatLayout(e, index, data)
      if (e.type == 'checkbox') {
        const keyElem = {
          index,
          elems: []
        }
        e.option?.forEach((option, index) => {
          const isDefaultChecked = _.find(e.props.selectedValues.listValue, (item) => option.value == item)
            ? true
            : false

          const isLastOption = index == e.option.length - 1

          const rules = _.get(e, 'rules.validation', [])
          const findMandatoryRule = _.find(rules, (rule) => rule.rule == 'mandatory')
          const isMandatory = findMandatoryRule !== undefined

          keyElem.elems.push({
            id: `${e.id}__${option.value}`,
            type: 'checkbox',
            id_Article_Champ: e.id_Article_Champ,
            className: `${e.props.displayFormat} checkbox ${index > 0 && 'hiddenLabel'}`,
            isMandatory,
            isLastOption,
            props: {
              label: option.primaryText,
              name: e.props.referenceCode,
              defaultChecked: isDefaultChecked,
              title: e.props.label,
              displayFormat: e.props.displayFormat,
              disabled: isDisabled,
              selectedValues: e.props.selectedValues
            }
          })
        })
        keyElemes.push(keyElem)
      }
    })

    let movedBy = 0
    keyElemes.forEach((elem) => {
      data.splice(elem.index + movedBy, 1, ...elem.elems)
      movedBy += elem.elems.length - 1
    })
    return data
  }
  async handelSelectOption({ optionName, newFormData, item, value, firstCall = false }) {
    const { formOptions } = this.state
    const { hasRestrictedCompos } = this.props

    const optionItemToPush = _.find(formOptions[optionName], (o) => {
      return o.value == item.props.value
    })

    let itemIndex = null
    const filedsofItem = _.filter(
      _.map(newFormData, (field, index) => {
        if (field.id == item.id) itemIndex = index
        const fieldTypeValue = _.get(field, 'props.typeValue.name', null)
        if (optionName == 'matiere') {
          return fieldTypeValue == 'matiere' && field.props.parentField == item.props.parentField ? index : -1
        }
        return fieldTypeValue == optionName ? index : -1
      }),
      (index) => index >= 0
    )
    newFormData[itemIndex].props.selected = value || ''
    newFormData[itemIndex].props.value = value || ''

    filedsofItem.map(async (fieldIndex) => {
      const fieldOptionValue = newFormData[fieldIndex].props.selected
      await filedsofItem.map(async (field2index, index) => {
        if (field2index !== fieldIndex) {
          const optionIndexToDelete = _.findIndex(
            newFormData[field2index].option,
            (option) => option.value == fieldOptionValue
          )
          if (optionIndexToDelete >= 0) {
            if (optionItemToPush && !firstCall) {
              newFormData[field2index].option.splice(optionIndexToDelete, 1, optionItemToPush)
            } else {
              newFormData[field2index].option.splice(optionIndexToDelete, 1)
            }
          } else if (optionItemToPush && !firstCall) {
            const hasOption = await _.filter(
              newFormData[field2index].option,
              (option) => option.value == optionItemToPush.value
            ).length
            const isSameItem = item.id == newFormData[field2index].id

            if (hasOption == 0 && !isSameItem) {
              await newFormData[field2index].option.splice(1, 0, optionItemToPush)
            }
          }
        }
      })
    })
    // If item has restricted compos and first call was made,
    if (hasRestrictedCompos && firstCall) {
      // We need to retrieve if "asterisks" items were used
      let oneAsteriskIdField = false
      let twoAsterisksIdField = false
      // First, we need to determine if there is any of them.
      _.map(newFormData, (fdata) => {
        if (!_.isEmpty(fdata.option)) {
          _.map(fdata.option, (o) => {
            const hasOneAsterisk = o.primaryText.substring(o.primaryText.length, o.primaryText.length - 2) == ' *'
            const hasTwoAsterisks = o.primaryText.substring(o.primaryText.length, o.primaryText.length - 3) == ' **'

            if (o.value == fdata.props.value && hasOneAsterisk) {
              oneAsteriskIdField = fdata.id_Article_Champ
              this.setState({ oneAsteriskFlag: fdata.id_Article_Champ })
            }
            if (o.value == fdata.props.value && hasTwoAsterisks) {
              twoAsterisksIdField = fdata.id_Article_Champ
              this.setState({ twoAsterisksFlag: fdata.id_Article_Champ })
            }
          })
        }
      })
      // And last, we need iterate again to disable values...
      newFormData = _.map(newFormData, (fdata) => {
        if (!_.isEmpty(fdata.option)) {
          fdata.option = _.map(fdata.option, (o) => {
            o.disabled = false
            // If option contains asterisk(s) but is not the field which has asterisk value, it needs to be disabled
            if (
              fdata.id_Article_Champ !== oneAsteriskIdField &&
              oneAsteriskIdField !== false &&
              o.primaryText.substring(o.primaryText.length, o.primaryText.length - 2) == ' *'
            )
              o.disabled = true
            if (
              fdata.id_Article_Champ !== twoAsterisksIdField &&
              twoAsterisksIdField !== false &&
              o.primaryText.substring(o.primaryText.length, o.primaryText.length - 3) == ' **'
            )
              o.disabled = true
            return o
          })
        }
        return fdata
      })
    }
    this.setState({ formData: newFormData })
  }
  handelAztecOnCheck(item, args, value) {
    const { formData } = this.state
    const { guid, handelFocusOut } = this.props
    if (guid.includes('commonData')) {
      const iD_Commande_Article_Champ = item.props.selectedValues.iD_Commande_Article_Champ
      const iD_SaleOrderItemField = iD_Commande_Article_Champ ? parseInt(iD_Commande_Article_Champ, 10) : 0
      item.props.value = value
      const itemsId = formData
        .filter((filed) => filed.id_Article_Champ == item.id_Article_Champ && filed.props.value)
        .map((filedValues) => {
          return parseInt(this.getValueId(filedValues.id), 10)
        })
      const m = {
        iD_SaleOrderItemField,
        iD_ItemField: parseInt(item.id, 10),
        primaryText: null,
        values: itemsId
      }
      handelFocusOut(m)
    }
  }

  validate(value, rules) {
    let isValid = true
    if (rules?.validation) {
      for (const data of rules.validation) {
        isValid = validation[data.rule](value ? value.toString() : '', data.value)
        if (!isValid) {
          return {
            isValid: false,
            message: data.message
          }
        }
      }
    }
    return {
      isValid: true,
      message: ''
    }
  }

  handelAztecOnBlur(item, args, value) {
    const { guid, handelFocusOut, data, backupData } = this.props
    let newData = backupData ? backupData.article_Champ_CommonData : data

    const oldItem = newData.find((d) => d.id_Article_Champ == item.id_Article_Champ) || {}
    const oldValue = _.get(oldItem, 'props.selectedValues[0].unicValue', '')
    if (guid.includes('commonData')) {
      const validate = this.validate(value, item.rules)
      if (!validate.isValid || oldValue.toString() == value.toString()) return
      const iD_Commande_Article_Champ = item.props.selectedValues.iD_Commande_Article_Champ
      const iD_SaleOrderItemField = iD_Commande_Article_Champ ? parseInt(iD_Commande_Article_Champ, 10) : 0
      const m = {
        iD_SaleOrderItemField,
        iD_ItemField: parseInt(item.id, 10),
        primaryText: value,
        values: []
      }
      handelFocusOut(m)
    }
  }
  handelAztecChange(item, args, indexOrValue, value) {
    const { formData, oneAsteriskFlag, twoAsterisksFlag } = this.state
    let newFormData = _.cloneDeep(formData)
    const typeValue = _.get(item, 'props.typeValue.name')
    const { guid, handelFocusOut, hasRestrictedCompos } = this.props

    if (
      guid.includes('commonData') &&
      ['selectfield', 'radio'].includes(item.type) &&
      !['matiere', 'matiere_percent', 'tissu', 'careinstruction'].includes(typeValue)
    ) {
      const validate = this.validate(value, item.rules)
      if (!validate.isValid && ['selectfield'].includes(item.type)) return
      const iD_Commande_Article_Champ = item.props.selectedValues.iD_Commande_Article_Champ
      const iD_SaleOrderItemField = iD_Commande_Article_Champ ? parseInt(iD_Commande_Article_Champ, 10) : 0
      let values = value ? [value] : []
      if (item.type == 'radio') {
        values = [indexOrValue]
      }
      const m = {
        iD_SaleOrderItemField,
        iD_ItemField: parseInt(item.id, 10),
        primaryText: null,
        values
      }
      handelFocusOut(m)

      return
    }
    if (typeValue == 'matiere_percent') {
      const matierePercentIndex = _.findIndex(newFormData, (filed) => filed.id == item.id)
      const newItem = newFormData[matierePercentIndex]
      newItem.props.value = indexOrValue || ''
      newItem.props.selectedValues.unicValue = indexOrValue || ''
      this.setState({ formData: newFormData })
    } else if (typeValue == 'matiere') {
      // If hasRestrictedCompos is raised, we are restricting composition based on user selection
      if (hasRestrictedCompos) {
        // All lists must be verified for asterisk presence ?
        // Passing asterisk flags to state ?

        // Item is indicating the field that was modified.
        // We need to check if selected value contains one or two asterisks
        const selectedLabelValue = _.find(item.option, { value: value })
        if (!_.isUndefined(selectedLabelValue)) {
          // Testing by extracting substring : because ' *' is included in ' **'...
          let hasOneAsteriskSelected =
            selectedLabelValue.primaryText.substring(
              selectedLabelValue.primaryText.length,
              selectedLabelValue.primaryText.length - 2
            ) == ' *'
          let hasTwoAsterisksSelected =
            selectedLabelValue.primaryText.substring(
              selectedLabelValue.primaryText.length,
              selectedLabelValue.primaryText.length - 3
            ) == ' **'

          // Now we will handle the other lists.
          // We will retire all options that contains the same amount of asterisks, except field selected
          newFormData = _.map(newFormData, (fdata) => {
            if (!_.isEmpty(fdata.option)) {
              fdata.option = _.map(fdata.option, (o) => {
                // Forcing disabling
                o.disabled = false

                // Then we will handle which item needs to be disabled
                // Case when item changed is the one we are iterating
                if (item.id_Article_Champ == fdata.id_Article_Champ) {
                  // One asterisk item was selected elsewhere, but we are iterating field which is not of the flag but contains one asterisk
                  if (
                    oneAsteriskFlag !== false &&
                    oneAsteriskFlag !== fdata.id_Article_Champ &&
                    o.primaryText.substring(o.primaryText.length, o.primaryText.length - 2) == ' *'
                  )
                    o.disabled = true
                  // Two asterisks item was selected elsewhere, but we are iterating field which is not of the flag but contains two asterisks
                  if (
                    twoAsterisksFlag !== false &&
                    twoAsterisksFlag !== fdata.id_Article_Champ &&
                    o.primaryText.substring(o.primaryText.length, o.primaryText.length - 3) == ' **'
                  )
                    o.disabled = true
                }
                // Case when item changed is not the one we are iterating
                else {
                  // If we HAVE selected asterisk item and iterated item contains one asterisk
                  if (
                    hasOneAsteriskSelected &&
                    o.primaryText.substring(o.primaryText.length, o.primaryText.length - 2) == ' *'
                  )
                    o.disabled = true
                  // If we HAVE selected two asterisks item and iterated item contains two asterisks
                  if (
                    hasTwoAsterisksSelected &&
                    o.primaryText.substring(o.primaryText.length, o.primaryText.length - 3) == ' **'
                  )
                    o.disabled = true
                  // If any flaged is raised, and items DOES contain asterisks AND we are iterating field different from the flag raised
                  if (
                    oneAsteriskFlag !== false &&
                    oneAsteriskFlag !== fdata.id_Article_Champ &&
                    o.primaryText.substring(o.primaryText.length, o.primaryText.length - 2) == ' *'
                  )
                    o.disabled = true
                  if (
                    twoAsterisksFlag !== false &&
                    twoAsterisksFlag !== fdata.id_Article_Champ &&
                    o.primaryText.substring(o.primaryText.length, o.primaryText.length - 3) == ' **'
                  )
                    o.disabled = true
                }

                // Special case, when we are NOT selecting asterisk item, but item selected match with one of the flags
                // In this case only, if item is disabled and does contain one or two asterisks, we are NOT disabling the item
                if (!hasOneAsteriskSelected && !hasTwoAsterisksSelected && item.id_Article_Champ == oneAsteriskFlag) {
                  if (o.disabled && o.primaryText.substring(o.primaryText.length, o.primaryText.length - 2) == ' *')
                    o.disabled = false
                } else if (
                  !hasOneAsteriskSelected &&
                  !hasTwoAsterisksSelected &&
                  item.id_Article_Champ == twoAsterisksFlag
                ) {
                  if (o.disabled && o.primaryText.substring(o.primaryText.length, o.primaryText.length - 3) == ' **')
                    o.disabled = false
                }

                return o
              })
            }
            return fdata
          })

          // Resetting the asterisks flag properly
          if (hasOneAsteriskSelected) this.setState({ oneAsteriskFlag: item.id_Article_Champ })
          else if (hasTwoAsterisksSelected) this.setState({ twoAsterisksFlag: item.id_Article_Champ })
          // For the next cases : if we have not one or two asterisks, and item changed is the one previously flagged, we reset it at false
          else if (!hasOneAsteriskSelected && item.id_Article_Champ == this.state.oneAsteriskFlag)
            this.setState({ oneAsteriskFlag: false })
          else if (!hasTwoAsterisksSelected && item.id_Article_Champ == this.state.twoAsterisksFlag)
            this.setState({ twoAsterisksFlag: false })
        }
      }

      this.handelSelectOption({
        optionName: 'matiere',
        newFormData,
        item,
        indexOrValue,
        value
      })
    } else if (typeValue == 'tissu') {
      this.handelSelectOption({
        optionName: 'tissu',
        newFormData,
        item,
        indexOrValue,
        value
      })
    } else if (typeValue == 'careinstruction') {
      this.handelSelectOption({
        optionName: 'careinstruction',
        newFormData,
        item,
        indexOrValue,
        value
      })
    } else if (item.type == 'textfield') {
      const itemIndex = _.findIndex(newFormData, (filed) => filed.id == item.id)
      const newItem = newFormData[itemIndex]
      newItem.props.value = indexOrValue || ''
      newItem.props.selectedValues.unicValue = indexOrValue || ''
      this.setState({ formData: newFormData })
    } else if (item.type == 'selectfield') {
      const itemIndex = _.findIndex(newFormData, (filed) => filed.id == item.id)
      newFormData[itemIndex].props.selected = value || ''
      newFormData[itemIndex].props.value = value || ''
      this.setState({ formData: newFormData })
    }
  }

  render() {
    const { guid } = this.props
    return (
      <AztecWrapper id="aztec">
        <Aztec
          guid={guid}
          data={this.state.formData}
          library={MUI}
          onCheck={this.handelAztecOnCheck}
          onChange={this.handelAztecChange}
          onBlur={this.handelAztecOnBlur}
          onSubmit={this.onSubmit.bind(this)}
          formRef={(form) => (this.formRef = form)}
          displayErrors={this.state.displayFormErrors}
          hasCertifLogo={this.props.hasCertifLogo}
          hasCertifMessage={this.props.hasCertifMessage}
          hasRestrictedCompos={this.props.hasRestrictedCompos}
        />
        <div className="clear" />
      </AztecWrapper>
    )
  }
}

AztecComponent.propTypes = {
  submitAction: PropTypes.func.isRequired,
  guid: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  aditionnelData: PropTypes.object,
  isValidQuantity: PropTypes.func,
  hasCertifLogo: PropTypes.bool,
  hasCertifMessage: PropTypes.bool,
  hasRestrictedCompos: PropTypes.bool,
  isDuplicate: PropTypes.bool,
  handelFocusOut: PropTypes.func,
  backupData: PropTypes.object
}

export default AztecComponent
