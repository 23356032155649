////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
import { isEmpty, isEqual } from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import Cancel from 'material-ui/svg-icons/navigation/cancel'
import FileDownload from 'material-ui/svg-icons/file/file-download'
import ViewDetail from 'material-ui/svg-icons/action/pageview'
import User from 'material-ui/svg-icons/action/account-circle'
import Print from 'material-ui/svg-icons/action/print'
import Repeat from 'material-ui/svg-icons/av/repeat'
import LocalShipping from 'material-ui/svg-icons/device/airplanemode-active'
import Edit from 'material-ui/svg-icons/image/edit'
import MenuItem from 'material-ui/MenuItem'
import { apiUpdateOrderStatusCancel, 
         apiRepeatOrder,
         apiTakeOwnership, 
         apiGetOrderArticleOFU, 
         apiGetReassignOrder, 
         apiShowModalReassign, 
         apiSetOrderID } from '../../../actions/orders'
import { resetMyDocumentation, apiGetMyDocumentation } from '../../../actions/myDocumentation'
import { checkRoleHasRightsToFunction } from '../../../containers/misc/allowedRolesPerFunction'
import * as c from '../../../constants'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import theme from '../../styledComponents/theme'
import Divider from 'material-ui/Divider'
import * as SC from '../../styledComponents'
import PageLoadTable from '../../PageLoadTable'
import SourceFileOrderModal from '../../../components/SourceFileOrderModal'

/////////STYLED/////////
const Link = styled.div`
  span:nth-child(1) > div > div > div:hover {
    color: ${theme.colors.blue};
    text-decoration: underline;
  }
`
/////////STYLED/////////

class OrdersFollowUpActions extends Component {
  state = {
    open: false,
    openRepeat: false,
    dataLoaded: false,
    openSourceFileInformation: false,
    isModalOpened: false,
    redirectInvoice: false
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillReceiveProps(nextProps) {
    const { row, orders } = nextProps
    if (nextProps.orders.isModalOpened !== this.props.isModalOpened) {
      this.setState({ isModalOpened: nextProps.orders.isModalOpened })
    }
    if(!isEmpty(orders[row.iD_Commande])) this.setState({ dataLoaded: true })
    if(!isEqual(this.props.documentations.list, nextProps.documentations.list) && !isEmpty(nextProps.documentations.list)) {
      hashHistory.push(`${c.myDocumentation}/?isRedirect`)
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleActionClick = () => {
    if (!this.props.row._isHydrated) this.props.apiGetOrderArticleOFU(this.props.row.iD_Commande)
  }

  handleOpen = name => { this.setState({ [name]: true, isModalOpened: true })
  }

  handleClose = name => { this.setState({ [name]: false, isModalOpened: false })
  }

  handleCancelSubmit = () => {
    const { apiUpdateOrderStatusCancel, row } = this.props
    apiUpdateOrderStatusCancel(row.iD_Commande)
    console.log("debug : cancel")
    this.handleClose('open')
  }

  handleRepeatSubmit = () => {
    const { apiRepeatOrder, row } = this.props
    apiRepeatOrder(row.iD_Commande)
    this.handleClose('openRepeat')
  }

  handleTakeOwnership() { this.props.apiTakeOwnership(this.props.row.iD_Commande)
  }

  handleReassign = (iD_Commande) => {
    this.props.apiGetReassignOrder(iD_Commande)
    this.props.apiSetOrderID({ orderId: iD_Commande })
    this.props.apiShowModalReassign({ openReassign: true, isModalOpened: true })
  }

  handleOpenSourceInformationModal = () => { this.setState({ openSourceFileInformation: true, isModalOpened: true })
  }

  handleCloseSourceInformationModal = () => { this.setState({ openSourceFileInformation: false, isModalOpened: false })
  }

  handleRedirectMyDocumentation = (updated_row) => {
    this.props.resetMyDocumentation()
    this.props.apiGetMyDocumentation(this.props.userId, {
      StartDocDate: moment(updated_row.date_Created).subtract(1,'hour'),
      EndDocDate: moment().toDate(),
      inputSearchBy: { key: updated_row.iD_Commande.toString(), 
                       value: 'mydocum_tableTitle_JPIPO' },
                       //ICI
      seeProformaInvoice: false,
      seeInvoice: true,
      //seeArchived: true,
    })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, myRole, row, orders } = this.props
    const updated_row = !isEmpty(orders[row.iD_Commande]) ? orders[row.iD_Commande] : row
    // /const displayCancel = [3, 4, 5, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18]
    const doNotDisplayRepeat = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 25]
    const displayARC = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
    const printInvoice = [18, 19, 20, 22]
    // /const ownerShip = hasOwnerShipIfFac(updated_row)
    const actions = [
      <SC.ButtonR_S style={{ margin: "0 20px" }}
                    onClick={() => this.handleClose('open')}
      >
        {trl.common__no}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handleCancelSubmit.bind(this)}>
        {trl.common__yes}
      </SC.ButtonPA_S>
    ]

    const actionsRepeat = [
      <SC.ButtonR_S style={{ margin: "0 20px" }}
                    onClick={() => this.handleClose('openRepeat')}
      >
        {trl.common__no}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handleRepeatSubmit.bind(this)}>
        {trl.common__yes}
      </SC.ButtonPA_S>
    ]
    return (
      <div>
        <ActionHelper
          onClick={this.handleActionClick}
          isModalOpened={this.state.isModalOpened}
        >
          <div style={{ minWidth: 180 }}>
            {
              !this.state.dataLoaded ?
                <PageLoadTable size={10} thickness={9} style={'linear'} color="inherit" />
                :
                <div>
                  <div>
                    <MenuItem
                      style={{ fontSize: theme.font.fontSize }}
                      primaryText={trl.ofu_lvlOrder_Action_ViewItems}
                      leftIcon={<ViewDetail />}
                      onClick={() => hashHistory.push(`${c.orderDetail}${this.props.row.iD_Commande}`)}
                    />
                  </div>

                  {updated_row.status.idStatus === 25 && (
                    <div>
                      <Divider style={{ height: '1.5px' }} />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        primaryText={trl.articleVariable_btn_viewBat}
                        leftIcon={<ViewDetail />}
                        onClick={() => hashHistory.push(`${c.batView}${updated_row.iD_Commande}/${updated_row.iD_Commande}`)}
                      />
                    </div>
                  )}
                  {
                    updated_row.canReassignGarmentMaker && checkRoleHasRightsToFunction(myRole, c.reassignOrder) &&
                    (<div>
                      <Divider style={{ height: '1.5px' }} />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        primaryText={trl.order_action_reassignOrder}
                        leftIcon={<Edit />}
                        onClick={() => this.handleReassign(updated_row.iD_Commande)}
                      />
                    </div>
                    )}
                  {!doNotDisplayRepeat.includes(updated_row.status.idStatus) && //TODO : fix styling
                    checkRoleHasRightsToFunction(myRole, c.seeOrderRepeat) && (
                      <Link>
                        <Divider style={{ height: '1.5px' }} />
                        <MenuItem
                          style={{ fontSize: theme.font.fontSize, cursor: 'pointer' }}
                          primaryText={trl.ofu_lvlOrder_Action_duplicateOrder}
                          onClick={() => this.handleOpen('openRepeat')}
                          leftIcon={<Repeat />}
                        />
                      </Link>
                    )}
                  {/* View Shipment */}
                  {/* Disabled for now  // do not delete */}
                  {c.displayViewShipment.includes(updated_row.status.idStatus) && checkRoleHasRightsToFunction(myRole, c.viewShipment) && (
                    <div>
                      <Divider style={{ height: '1.5px' }} />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        primaryText={trl.ofu_lvlOrder_Action_ViewShipment}
                        leftIcon={<LocalShipping />}
                        onClick={() => hashHistory.push(`${c.orderDetail}${updated_row.iD_Commande}${c.shipmentHistory}`)}
                      />
                    </div>
                  )}
                  {/* Download ARC */}
                  {displayARC.includes(updated_row.status.idStatus) &&
                    updated_row.hasArc &&
                    updated_row.arcNumber !== null &&
                    (myRole !== 'PAdmin' && myRole !== 'POP') &&
                    (updated_row.iD_Commande_Externe_SAP !== null && updated_row.iD_Commande_Externe_SAP !== '') && (
                      <Link>
                        <Divider style={{ height: '1.5px' }} />
                        <MenuItem
                          style={{ fontSize: theme.font.fontSize }}
                          primaryText={trl.ofu_lvlOrder_Action_DownloadARC}
                          onClick={() => window.open(`${c.downloadARC}${updated_row.arcNumber}.pdf`, '_blank')}
                          leftIcon={<FileDownload />}
                        />
                      </Link>
                    )}
                  {/* Cancel Order */}
                  {updated_row.canCancel && (
                    <Link>
                      <Divider style={{ height: '1.5px' }} />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        primaryText={trl.ofu_lvlOrder_Action_CancelOrder}
                        onClick={() => this.handleOpen('open')}
                        leftIcon={<Cancel />}
                      />
                    </Link>
                  )}
                  {printInvoice.includes(updated_row.status.idStatus) && checkRoleHasRightsToFunction(myRole, c.actionPrintInvoice) && updated_row.hasInvoice && (
                    <div>
                      <Divider style={{ height: '1.5px' }} />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        primaryText={trl.ofu_lvlOrder_Action_printInvoice}
                        leftIcon={<Print />}
                        onClick={() => this.handleRedirectMyDocumentation(updated_row)}
                      />
                    </div>
                  )}
                  {updated_row.haveBatHistory && (
                    <div>
                      <Divider style={{ height: '1.5px' }} />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        primaryText={trl.ofu_lvlOrder_Action_BATHistory}
                        leftIcon={<ViewDetail />}
                        onClick={() => hashHistory.push(`${c.batHistory}${updated_row.iD_Commande}`)}
                      />
                    </div>
                  )}

                  {
                    // Take ownership - Making sure that row is properly updated before showing action or not
                    !updated_row.hasOwnerShip && !isEmpty(orders[row.iD_Commande]) && checkRoleHasRightsToFunction(myRole, c.funcUpdateId) && (
                      <div>
                        <Divider style={{ height: '1.5px' }} />{' '}
                        <MenuItem
                          style={{ fontSize: theme.font.fontSize }}
                          primaryText={trl.ofu_lvlOrder_Action_TakeOrderCreation}
                          leftIcon={<User />}
                          onClick={() => this.handleTakeOwnership()}
                        />
                      </div>
                    )}

                </div>
            }
            {/* SEE SOURCE FILE */}
            {
              updated_row.canSeeSourceFile &&
              <div>
                <Divider style={{ height: '1.5px' }} />{' '}
                <MenuItem
                  style={{ fontSize: theme.font.fontSize }}
                  primaryText={trl.common_sourceFile_tableAction__seeSourceFile} //
                  leftIcon={<ViewDetail />}
                  onClick={() => this.handleOpenSourceInformationModal()}
                />
              </div>
            }
          </div>

        </ActionHelper>

        <Dialog
          title={trl.ofu_actions_repeatOrder}
          actions={actionsRepeat}
          modal={false}
          open={this.state.openRepeat}
          onRequestClose={() => this.handleClose('openRepeat')}
        />
        <Dialog
          title={trl.common__order_cancel_confirm || ' '}
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={() => this.handleClose('open')}
        />

        {/* SOURCE FILE  */}
        {
          this.state.openSourceFileInformation &&
          <SourceFileOrderModal
            open={this.state.openSourceFileInformation}
            autoScrollBodyContent={true}
            handleClose={this.handleCloseSourceInformationModal}
            trl={trl}
            row={row}
          >
          </SourceFileOrderModal>
        }
      </div>
    )
  }
}

OrdersFollowUpActions.propTypes = {
  row: PropTypes.object.isRequired,
  trl: PropTypes.object.isRequired,
  myRole: PropTypes.string.isRequired,
  apiRepeatOrder: PropTypes.func.isRequired,
  apiUpdateOrderStatusCancel: PropTypes.func.isRequired
}

const mapStateToProps = ({ languages, auth, orders, myDocumentation }) => ({ trl: languages.siteLanguage.keys, myRole: auth.user_role, orders: orders, documentations: myDocumentation, userId: auth.user_id })

export default connect(
  mapStateToProps,
  { apiUpdateOrderStatusCancel, apiRepeatOrder, apiTakeOwnership, apiGetOrderArticleOFU, apiGetReassignOrder, apiShowModalReassign, apiSetOrderID, apiGetMyDocumentation, resetMyDocumentation }
)(OrdersFollowUpActions)
