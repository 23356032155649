////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import styled from 'styled-components'
import Dialog from 'material-ui/Dialog'
import { hashHistory } from 'react-router'
import _, { isUndefined } from 'lodash'
///////COMPONENTS///////
import PageLoad from '../../../components/PageLoad'
import { PageHeader, TotalOrder, HelpComponent } from '../../../components/sharedComponents'
import { P2, PageGutter, H2 } from '../../../components/styledComponents'
import { apiGetOrderArticle, apiUpdateOrderStatusQualified, apiOrderSapCreate, apiConfirmOrder} from '../../../actions/orders'
import FlatButton from 'material-ui/FlatButton'
import OrderConfirmTable from '../../../components/Sematable/Tables/OrderConfirmTable'
import OrderDetailInformation from '../../OrderDetail/OrderDetailInformation'
import { styles } from '../misc/formStyles'
import CircularProgress from 'material-ui/CircularProgress'
import { renderCheckbox } from '../misc/FormHelpers'
import * as c from '../../../constants'
import DisplayAddresses from '../../../components/DisplayAddresses'
import { hasOwnerShipIfFac } from '../../../utils/apiUtils'
import * as SC from '../../../components/styledComponents'

/////////STYLED/////////
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
/////////STYLED/////////
class ConfirmOrder extends Component {
  state = {
    open: false,
    showHelpComponent: false,
    submitting: false
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.apiGetOrderArticle(this.props.routeParams.orderId)
  }
  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleOpen = () => this.setState({ open: true })

  handleClose = () => this.setState({ open: false })

  renderYesNo = v => <span>{v ? this.props.trl.common__yes : this.props.trl.common__no}</span>

  onSubmit = () => {
    const { orders, routeParams, apiConfirmOrder } = this.props
    const order = orders[routeParams.orderId]
    const { iD_Commande, status } = order
    const iD_Status = status.idStatus
    this.setState({ submitting: true })
    apiConfirmOrder({ iD_Commande, iD_Status })
    setTimeout(() => {
      this.setState({ submitting: false })
    }, 4000)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { submitting } = this.state
    const { trl, orders, error, handleSubmit, isDraftValue, routeParams, myRole } = this.props
    const order = orders[routeParams.orderId]
    const actions = [<FlatButton label={trl.cgv__popup_close_btn} primary={true} onTouchTap={this.handleClose} />]
    if (_.isEmpty(orders) || isUndefined(order)) {
      return null
    }
    let hasArticlesAdded = false
    _.toArray(order.commande_Articles).map(commande_Article => {
      if(commande_Article.status.idStatus == 29) hasArticlesAdded = true
    })
    const ownerShip = hasOwnerShipIfFac(order)
    const isDisabled = order.status.idStatus > 8 &&  !hasArticlesAdded || !ownerShip
    if (_.isEmpty(orders) || isUndefined(order)) return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    return (
      <div>
        <PageHeader
          title={`PO ${order.iD_Commande} ${trl.order_confirm__title}`}
          subtitle={trl.order_confirm__subtitle}
          base64Logo={
            order && order.brandLogo
              ? order.brandLogo
              : null
          }
        />
        {/* TOP DESCRIPTION, SAME COMPONENT USED FOR ITEM DETAIL PAGE */}
        <PageGutter>
          <OrderDetailInformation
            trl={trl}
            order={order}
            myRole={myRole}
          />
          {/* ORDER CONFIRM TABLE WITH PRICES */}
          <br />
          <br />
          <H2 style={{ fontSize: '12px' }}>{trl.orderSumry_Text_Subtitle1}</H2>
          <br />
          {order.commande_Articles && (
            <OrderConfirmTable
              data={_.toArray(_.filter(order.commande_Articles, ca => ![23, 32].includes(ca.status.idStatus)))}
            />
          )}
          <SC.ButtonS
            style={{ float: 'left' }}
            onClick={() => hashHistory.push(`${c.orderDetail}${routeParams.orderId}`)}
          >
            {trl.orderSumry_Btn_ChangeItems}
          </SC.ButtonS>
          <TotalOrder trl={trl} order={order} />
          <br />
          <br />
          <br />
          <br />
          <H2 style={{ fontSize: '12px' }}>{trl.orderSumry_Text_Subtitle2}</H2>
          <br />
          {/* 5 */}
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {/* Left */}
              <H2 style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>{trl.orderSumry_Text_DeliveryAddress}</H2>
              <br />
              {order.delivery_Address && <DisplayAddresses path={order.delivery_Address} trl={trl} />}
              <br />
              <SC.ButtonS
                disabled={isDisabled}
                onClick={() => hashHistory.push(`${c.orderDetail}${routeParams.orderId}${c.orderDeliveryDetails}`)}
              >
                {trl.order_confirm__delivery_address_btn}
              </SC.ButtonS>
            </div>
            {/* Middle */}
            <div className="col-xs-12 col-sm-6">
              <H2 style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>{trl.orderSumry_Text_InvoiceAddress}</H2>
              <br />
              <H2 style={{ fontWeight: 'bold', fontSize: '12px' }}>{trl.common__billing_address_title}</H2>
              {order.billing_Address && <DisplayAddresses path={order.billing_Address} trl={trl} />}
            </div>
          </div>
          {/* 6 */}
          <br />
          <br />
          <br />
          <H2 style={{ fontSize: '12px' }}>{trl.orderSumry_Text_Subtitle3}</H2>
          <br />
          <div className="row">
            <div className="col-xs-6">
              <P2 style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>{trl.orderSumry_Text_ShippingMode}</P2>{' '}
              <br />
              <P2 style={{ fontSize: '12px' }}>{order.carrier_Name}</P2>
              <br />
              <SC.ButtonS
                disabled={isDisabled}
                onClick={() => hashHistory.push(`${c.orderDetail}${routeParams.orderId}${c.orderDeliveryDetails}`)}
              >
                {trl.orderSumry_Btn_ChangeShipMode}
              </SC.ButtonS>
            </div>
            <div className="col-xs-6" style={styles.boxWithBorder}>
              <P2 style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px' }}>{trl.orderSumry_Text_OrderInfo}</P2>
              <br />
              <P2 style={{ fontSize: '12px' }}>
                <span>{trl.common__payment_condition}:</span> <span>{order.payment_Terms}</span>
              </P2>
              <P2 style={{ fontSize: '12px' }}>
                <span>{trl.common__total_estimated_weight}:</span>
                <span>{order.weight_Total}</span>
              </P2>
              <P2 style={{ fontSize: '12px' }}>
                <span>{trl.common__incoterm}:</span>
                <span>{order.incoterm}</span>
              </P2>
              <P2 style={{ fontSize: '12px' }}>
                <span>{trl.common__allow_partial_delivery}:</span>
                <span>{this.renderYesNo(order.allow_Partial_Delivery)}</span>
              </P2>
            </div>
          </div>
          {/* 8 info de cout de la command*/}
          {/* 9 Submission */}
          <div className="row">
            <div className="col-xs-12">
              <form onSubmit={handleSubmit(this.onSubmit).bind(this)}>
                <br />
                <br />
                <br />
                <br />
                <div className="row">
                  <div className="col-xs-12">
                    <div>
                      {!isDisabled && (
                        <div>
                          <Field
                            name="isDraft"
                            component={renderCheckbox}
                            label={trl.cgv__agreement || ' '}
                            style={{ marginTop: 30, fontSize: '12px' }}
                            fullWidth={false}
                          />
                          <FlatButton
                            onTouchTap={this.handleOpen}
                            label={trl.cgv__popup_open_btn}
                            labelStyle={{ color: 'black', textDecoration: 'underline', fontSize: '12px' }}
                            secondary={true}
                          />
                        </div>
                      )}
                      <Dialog
                        title={trl.cgv__popup_title || ' '}
                        actions={actions}
                        modal={false}
                        open={this.state.open}
                        onRequestClose={this.handleClose}
                        autoScrollBodyContent={true}
                        contentStyle={{
                          width: '100%',
                          maxWidth: 'none'
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: `${trl.cgv__content}` }} />
                      </Dialog>
                    </div>
                  </div>
                </div>
                {/* HELP COMPONENT */}
                <br />
                <div style={{ position: 'relative' }}>
                  <img
                    src={require('../../../assets/centre_appel.png')}
                    alt="call center"
                    style={{ marginBottom: 30, display: 'block', cursor: 'pointer', width: 50 }}
                    onMouseEnter={() => this.setState({ showHelpComponent: true })}
                    onMouseLeave={() => this.setState({ showHelpComponent: false })}
                  />
                  {this.state.showHelpComponent && <HelpComponent />}
                </div>
                <ButtonWrapper>
                  {/* LEFT */}
                  <SC.ButtonR_L
                    type="button"
                    onClick={() => hashHistory.push(`${c.orderDetail}${routeParams.orderId}${c.orderDeliveryDetails}`)}
                  >
                    {trl.orderSumry_Btn_Back}
                  </SC.ButtonR_L>
                  {/* MIDDLE */}
                  <div />
                  {/* RIGHT */}
                  <div>
                    {!isDisabled && (
                      <div style={{ display: 'inline-block' }}>
                        <SC.ButtonPA_L
                          disabled={orders.loading || !isDraftValue}
                          type="submit"
                        >
                          {trl.order_confirm__confirm_btn}
                        </SC.ButtonPA_L>
                        {/* Make sure that loader is not shown as long as CGV is not checked and submit not clicked */}
                        {orders.loading && submitting && isDraftValue && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
                      </div>
                    )}
                    <div>{error && <p style={styles.loginError}>{error}</p>}</div>
                  </div>
                </ButtonWrapper>
              </form>
            </div>
          </div>
        </PageGutter>
      </div>
    )
  }
}

const selector = formValueSelector('confirmOrder')
const mapStateToProps = state => ({
  orders: state.orders,
  isDraftValue: selector(state, 'isDraft'),
  myRole: state.auth.user_role,
  companies: state.companies
})

ConfirmOrder.propTypes = {
  apiGetOrderArticle: PropTypes.func.isRequired,
  trl: PropTypes.object.isRequired
}

ConfirmOrder = reduxForm({
  form: 'confirmOrder',
  touchOnBlur: false,
  errors: {}
})(ConfirmOrder)

export default connect(
  mapStateToProps,
  {
    apiGetOrderArticle,
    apiUpdateOrderStatusQualified,
    apiOrderSapCreate,
    apiConfirmOrder
  }
)(ConfirmOrder)
